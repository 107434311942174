@tailwind base;
@tailwind components;
@tailwind utilities;

.input-type-text-no{
    justify-content: center;
    display: flex;
    margin: 0 auto;
    display: flex;
    padding-top: 18rem;
}